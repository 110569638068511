import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "promo-links" }
const _hoisted_3 = { class: "promo-links-list" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "disclaimer-container" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["textContent"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LanguageToggle = _resolveComponent("LanguageToggle")
  const _component_OutgoingAffiliate = _resolveComponent("OutgoingAffiliate")

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    (!$options.hideToggle)
      ? (_openBlock(), _createBlock(_component_LanguageToggle, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: $options.preferredRoute,
            target: "_blank",
            onClick: _cache[0] || (_cache[0] = $event => ($options.track('click', {
                            link_text: $setup.t('links:reward_terms_and_conditions_copy'),
                            link_url: $options.preferredRoute,
                        })))
          }, _toDisplayString($setup.t("links:reward_terms_and_conditions_copy")), 9, _hoisted_4)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: $options.faqRoute,
            onClick: [
              _cache[1] || (_cache[1] = $event => ($options.track('click', {
                            link_text: $setup.t('links:faq_copy'),
                            link_url: $options.faqRoute,
                        }))),
              _cache[2] || (_cache[2] = _withModifiers((...args) => ($options.openPopup && $options.openPopup(...args)), ["prevent"]))
            ]
          }, _toDisplayString($setup.t("links:faq_copy")), 9, _hoisted_5)
        ]),
        (!$options.inIframe)
          ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
              _createVNode(_component_OutgoingAffiliate, {
                "affiliate-id": "safety_information",
                target: "_blank"
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", {
        class: "disclaimer",
        innerHTML: $options.version
      }, null, 8, _hoisted_8),
      _createElementVNode("p", {
        class: "disclaimer copy-clearance-number",
        innerHTML: $options.copyClearance
      }, null, 8, _hoisted_9),
      ($options.isLoggedIn && $data.shouldDisplayLogout)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "logout-link",
            onClick: _cache[3] || (_cache[3] = (...args) => ($options.logoutClicked && $options.logoutClicked(...args))),
            textContent: _toDisplayString($setup.t('buttons:logout'))
          }, null, 8, _hoisted_10))
        : _createCommentVNode("", true)
    ])
  ]))
}