import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-sitekey"]

import { computed, nextTick, onMounted, onBeforeUnmount, useTemplateRef } from 'vue';
    import { useStore } from 'vuex';

    
export default {
  __name: 'BaseRecaptcha',
  props: {
        isError: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
            default: '',
        },
        isAutomatedTest: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
  setup(__props, { expose: __expose }) {

    const { grecaptcha } = window;

    

    const store = useStore();
    const gRecaptchaElement = useTemplateRef('gRecaptchaElement');

    const recaptchaV2PublicKey = computed(() => store.state.app.recaptchaV2PublicKey);

    const resizeCaptcha = () => {
        const recaptchaContainerEl = document.querySelector('.g-recaptcha');
        const sizeEl = recaptchaContainerEl.children[0];
        const { width } = recaptchaContainerEl.getBoundingClientRect();
        sizeEl.style.transform = sizeEl.style.webkitTransform = 'scale(' + (width / 304) +  ')';
        sizeEl.style.transformOrigin = '0 0';
        sizeEl.style.width = width + 'px';
        sizeEl.style.height = width * 78 / 304 + 'px';
    };

    const recaptchaExpired = () => {
        setTimeout(resizeCaptcha, 250);
    };

    const reset = () => grecaptcha?.reset();

    let recaptchaInterval;

    onMounted(() => {
        window.recaptchaComplete = resizeCaptcha;
        window.recaptchaExpired = recaptchaExpired;

        nextTick(() => {
            recaptchaInterval = setInterval(() => {
                if (grecaptcha?.render && gRecaptchaElement.value?.matches(':empty')) {
                    grecaptcha.render(gRecaptchaElement.value, {
                        sitekey: recaptchaV2PublicKey.value,
                        'expired-callback': window.recaptchaExpired,
                    });
                    resizeCaptcha();
                    window.addEventListener('resize', resizeCaptcha);
                    clearInterval(recaptchaInterval);
                }
            }, 500);
        });
    });

    onBeforeUnmount(() => {
        window.removeEventListener('resize', resizeCaptcha);
        clearInterval(recaptchaInterval);
    });

    __expose({
        reset,
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["g-recaptcha-response-container", { error: __props.isError }])
  }, [
    _withDirectives(_createElementVNode("p", { class: "g-recaptcha-inline-error" }, _toDisplayString(__props.label), 513), [
      [_vShow, __props.isError]
    ]),
    _createElementVNode("div", {
      ref_key: "gRecaptchaElement",
      ref: gRecaptchaElement,
      class: "g-recaptcha",
      "data-callback": "recaptchaComplete",
      "data-expired-callback": "recaptchaExpired",
      "data-sitekey": recaptchaV2PublicKey.value
    }, null, 8, _hoisted_1)
  ], 2))
}
}

}