
    import { useI18next } from '@composables/i18next';
    import { dataLayerTracking } from '../utilities';
    import BaseModal from './BaseModal';

    export default {
        name: 'VideoModal',

        extends: BaseModal,

        props: {
            pageName: {
                type: String,
                default: '',
            },
            reward: {
                type: Object,
                default () {
                    return {};
                },
            },
        },

        setup () {
            const { t } = useI18next(['buttons']);
            return { t };
        },

        data () {
            return {
                modalName: 'video-modal',
                tracking: {
                    recentlyTrackedPercent: null,
                    video_start: {
                        options: {
                            video_percent: 0,
                        },
                    },
                    video_progress: {
                        options: {
                            video_percent: 10,
                        },
                    },
                    video_complete: {
                        options: {
                            video_percent: 100,
                        },
                    },
                },
            };
        },

        mounted () {
            this.$refs.video.addEventListener('timeupdate', (event) => { this.videoUpdate(event); });
        },

        methods: {
            videoUpdate (event) {
                const { target } = event;
                const {
                    currentSrc: video_url,
                    currentTime,
                    duration,
                } = target;
                const video_current_time = Math.trunc(currentTime);
                const video_duration = Math.trunc(duration);
                const video_percent = Math.trunc((currentTime / duration) * 100);
                const targetPercentages = new Set([
                    0,
                    10,
                    25,
                    50,
                    75,
                    100,
                ]);

                if (
                    !targetPercentages.has(video_percent) ||
                    this.tracking.recentlyTrackedPercent === video_percent
                ) {
                    return;
                }

                const trackingOptions = {
                    video_current_time,
                    video_duration,
                    video_percent,
                    video_provider: (new URL(video_url)).hostname,
                    video_url,
                };

                const trackId = video_percent <= 0 ? 'video_start' : video_percent >= 100 ? 'video_complete' : 'video_progress';

                this.track(trackId, trackingOptions);

                this.tracking.recentlyTrackedPercent = video_percent;
            },

            track (event, overrides) {
                const { options } = this.tracking[event];
                dataLayerTracking({
                    event,
                    event_data: {
                        video_current_time: 0,
                        video_duration: 0,
                        video_percent: 0,
                        video_provider: '',
                        video_title: this.pageName,
                        video_url: '',
                        visible: true,
                        ...options,
                        ...overrides,
                    },
                });
            },
        },
    };
