
    import { useI18next } from '@composables/i18next';
    import OutgoingAffiliate from '@components/OutgoingAffiliate.vue';
    import BasePage from './BasePage';
    export default {
        name: 'RedirectPage',

        components: {
            OutgoingAffiliate,
        },

        extends: BasePage,


        setup () {
            const { t } = useI18next(['redirect', 'buttons', 'links']);
            return { t };
        },

        data () {
            return {
                pageName: 'redirect',
                isProd: undefined,
            };
        },

        mounted () {
            setTimeout(() => {
                this.isProd = document.location.href.match(/prod|promo.acuvue/);
                // redirect only for prod apollo or vanity url
                if (this.isProd) {
                    top.location.href = '/redirect_to_affiliate?affiliate_id=redirect_rewards';
                }
            }, 3000);
        },
    };
