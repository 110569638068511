
    import { mapState } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import { PhoneIcon } from '@components/images';
    import OutgoingAffiliate from '@components/OutgoingAffiliate.vue';
    import { dataLayerTracking } from '../utilities';
    import { openPopup } from '../popup';
    import BaseModal from './BaseModal';

    export default {
        name: 'QuestionsModal',

        components: {
            PhoneIcon,
            OutgoingAffiliate,
        },

        extends: BaseModal,

        props: {
            pageName: {
                type: String,
                required: true,
            },

            overrideShowRewardsChat: {
                type: Boolean,
                default: false,
            },

            rewardsChatId: {
                type: String,
                required: true,
            },

            lensAssistChatId: {
                type: String,
                required: true,
            },

            showRewardsChat: Boolean,

            disableContactsChat: Boolean,

            rewardsOnline: Boolean,
            rewardsOffline: Boolean,
            contactsOnline: Boolean,
            contactsOffline: Boolean,
        },

        setup () {
            const { t } = useI18next(['modal_content']);
            return { t };
        },

        data () {
            return {
                modalName: 'questions',
                search: '',
                styleObservers: [],
                showRewardsOnline: this.rewardsOnline,
                showRewardsOffline: this.rewardsOffline,
                showContactsOnline: this.contactsOnline,
                showContactsOffline: this.contactsOffline,
                tracking: {
                    click: {
                        options: {
                            component_name: 'questions-modal',
                            type: 'button',
                            link_text: '',
                            link_url: window.location.href,
                            link_hostname: `${window.location.hostname}/${this.$store.state.app.locale}`,
                            outbound: false,
                        },
                    },
                },
            };
        },

        computed: {
            ...mapState({
                firstName: state => state.profile.first_name,
            }),
        },

        mounted () {
            this.addStyleObserver(document.querySelector('#lensassist_proxies > span:nth-child(1)'));
            this.addStyleObserver(document.querySelector('#lensassist_proxies > span:nth-child(2)'));
            this.addStyleObserver(document.querySelector('#lensassist_proxies > span:nth-child(3)'));
            this.addStyleObserver(document.querySelector('#lensassist_proxies > span:nth-child(4)'));
        },

        beforeUnmount () {
            for (let i = 0; i < this.styleObservers.length; i++) {
                this.removeStyleObserver(this.styleObservers[i]);
            }
        },

        methods: {
            openPopup,

            openLiveAgentChat (whichChat) {
                window.liveagent.startChat(whichChat);
            },

            searchFAQ (event) {
                const href = `?locale=${this.app.locale}/#/faq?search=${encodeURIComponent(this.search.trim())}`;
                this.track('click', {
                    link_text: this.t('questions_modal.rewards.search.cta'),
                    link_url: href,
                });

                this.openPopup({
                    target: {
                        href,
                        title: '',
                    },
                });
            },

            addStyleObserver (element) {
                const config = { attributes: true };
                const callback = () => {
                    this.handleStyleObservationChange(element);
                };
                const observer = new MutationObserver(callback);
                observer.observe(element, config);
                this.styleObservers.push(observer);
            },

            handleStyleObservationChange (element) {
                this[element.getAttribute('observable')] = element.style.display !== 'none';
            },

            removeStyleObserver (observer) {
                observer.disconnect();
            },

            track (event, overrides) {
                const { options } = this.tracking[event];
                dataLayerTracking({
                    event,
                    event_data: {
                        ...options,
                        ...overrides,
                    },
                });
            },
        },
    };
