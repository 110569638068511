
    import { mapActions } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import { PlacemarkerIcon } from '@components/images/';
    import ECPSearchAndSelect from '../components/ECPSearchAndSelect.vue';
    import LoadingIcon from '../../admin/js/components/LoadingIcon.vue';
    import openModal, { ErrorModal } from '../modals';
    import BaseModal from './BaseModal';

    export default {
        name: 'LocationServiceModal',

        components: {
            PlacemarkerIcon,
            ECPSearchAndSelect,
            LoadingIcon,
        },

        extends: BaseModal,

        setup () {
            const { t } = useI18next(['buttons', 'location_service_modal']);
            return { t };
        },

        data () {
            return {
                modalName: 'location-service-modal',
                locationServicesAllowed: undefined,
                searchCompleted: false,
            };
        },

        methods: {
            ...mapActions({
                setEcp: 'estimator/setEcp',
                submitEyeCareProfessionalSearch: 'patient/submitEyeCareProfessionalSearch',
                submitSearchByRadius: 'patient/submitSearchByRadius',
            }),
            areLocationServicesAllowed (choice) {
                this.selectionWasMade = true;
                if (!navigator.geolocation) {
                    this.locationServicesAllowed = false;
                }
                this.locationServicesAllowed = choice;
                if (this.locationServicesAllowed) {
                    this.searchGeo();
                }
                else {
                    this.closeModal();
                    this.$router.push('/estimator_ecp_search');
                }
            },
            async closeModalAndGoToPatientProductIntake (ecp) {
                this.setEcp(ecp);
                await this.closeModal();
                this.$router.push('/estimator_intake');
            },
            async closeModalAndShowManualEcpSearch () {
                await this.closeModal();
                this.$router.push('/estimator_ecp_search');
            },
            async searchGeo () {
                navigator.geolocation.getCurrentPosition(
                    this.searchGeoSuccess,
                    this.searchGeoError,
                );
            },
            async searchGeoSuccess ({ coords }) {
                this.searchCompleted = false;

                const { latitude, longitude } = coords;
                await this.submitSearchByRadius({
                    params: {
                        latitude,
                        longitude,
                        radius: '5',
                    },
                });
                this.searchCompleted = true;
            },
            async searchGeoError () {
                await openModal(ErrorModal);
                this.locationServicesAllowed = false;
            },
            async submitEcpData () {
                this.searchCompleted = false;

                const ecpValidator = this.$refs['validator-ecp'];
                await ecpValidator.validate();

                if (!ecpValidator.isValid) return;
                try {
                    await this.submitEyeCareProfessionalSearch({ params: { ...this.ecpData } });
                    this.scrollToTop();
                }
                catch (error) {
                    console.error('error', error);
                }

                this.searchCompleted = true;
            },
        },
    };
