
    import { useI18next } from '@composables/i18next';
    import ScanHeadlineTooltip from '@components/tooltips/ScanHeadlineTooltip';
    import { track } from '../plugins/WebAnalytics';
    import { dataLayerTracking } from '../utilities';
    import BaseModal from './BaseModal';

    export default {
        name: 'ScanditErrorModal',

        components: {
            ScanHeadlineTooltip,
        },

        extends: BaseModal,

        props: {
            eye: {
                type: String,
                default: undefined,
            },
            error: {
                type: String,
                default: 'not_found',
            },
        },

        setup () {
            const { t } = useI18next(['modal_content']);
            return { t };
        },

        data () {
            return {
                modalName: 'ScanditErrorModal',
                scans: [],
                leftRightSame: false,
            };
        },

        mounted () {
            dataLayerTracking({
                event: 'form_error',
                event_data: {
                    error_message: this.t('scan_error_modal.headline'),
                    name: 'rewards',
                    type: 'scan_and_submit',
                    generate_lead: 'False',
                    step: 'step_3',
                },
            });
        },

        methods: {
            onScan (data) {
                this.closeModal({ data });
            },

            onScanError (error) {
                this.closeModal({ error });
            },
            trackAndClose (options) {
                track('Link', {
                    category: 'Tap',
                    label: options.label,
                    value: 1,
                });
                this.closeModal({ [options.type]: true });
            },
        },
    };
