
    import axios from 'axios';
    import { mapActions, mapState } from 'vuex';
    import { useI18next } from '@composables/i18next';

    import { promocode as promocodeValidator } from '@lib/validation/client-validators';
    import Validator from '@components/Validator';
    import { BaseInput } from '@components/form/index';
    import api from '../api';
    import { dataLayerTracking } from '../utilities';

    export default {
        name: 'PromoCodeForm',

        components: {
            Validator,
            BaseInput,
        },

        props: {
            submissionId: {
                type: String,
                default: undefined,
            },
            previousPromocodeApplied: {
                required: true,
                type: Object,
            },
            edit: {
                type: String,
                default: '',
            },
        },

        emits: ['promocodeApplied'],

        setup () {
            const { t } = useI18next(['promocode_form']);
            return { t };
        },

        data () {
            return {
                isSubmittingPromocode: false,
                promocodeApplied: false,
                promocodeFormData: {
                    code: undefined,
                    purchase_submission_id: this.submissionId,
                },
                promocodeValidator,
                promocodeBonusAmount: 0,
                hasCodeAlreadyUsedError: false,
            };
        },

        computed: {
            ...mapState(['patient']),
            formInputDisabled () {
                return modelProperty => this.edit == 'current' && !this.unResolvedFormValues.includes(`patient_${modelProperty}`);
            },
            codePreviouslyUsed () {
                const keys = Object.keys(this.previousPromocodeApplied);
                if (keys.length) {
                    this.getPreviousBonus();
                    return true;
                }
                return false;
            },
        },

        methods: {
            ...mapActions({
                makeCall: 'purchase/makeCall',
            }),

            async applyPromocode () {
                this.hasCodeAlreadyUsedError = false;
                this.$refs.promocodeValidatorEl.resetErrors();
                const formToValidate = this.$refs.promocodeValidatorEl;
                await formToValidate.validate();
                if (!formToValidate.isValid && this.isSubmittingPromocode) return;
                this.isSubmittingPromocode = true;

                try {
                    // Note: code_class is no longer required within promocodeFormData.
                    const response = await axios.post(`${api.base}/purchase-submissions/submit-promo-code`, this.promocodeFormData);
                    this.promocodeApplied = true;
                    this.promocodeBonusAmount = response.data.rewardEarned;
                    dataLayerTracking({
                        event: 'form_submit',
                        event_data: {
                            name: 'rewards',
                            type: 'promo_code',
                            generate_lead: 'False',
                            step: 'step_9',
                        },
                    });
                    this.$emit('promocodeApplied', { promocodeBonusAmount: this.promocodeBonusAmount });
                }
                catch (error) {
                    const { response } = error;
                    if (response?.data?.errors) {
                        // Code was formatted incorrectly.
                        this.$refs.promocodeValidatorEl.setAllErrors(response.data.errors);
                    }
                    else {
                        // Code was formatted correctly but was not valid OR
                        // has already been consumed OR
                        // something else bad happened.
                        this.$refs.promocodeValidatorEl.setAllErrors([{ error: 'VALIDATION', message: 'failed validation', name: 'code' }]);
                        this.hasCodeAlreadyUsedError = response?.data?.message == 'code is already in use';
                        console.error(error);
                    }
                    dataLayerTracking({
                        event: 'form_error',
                        event_data: {
                            error_message: this.t(this.hasCodeAlreadyUsedError ? 'errors.code_already_used' : 'errors.code_invalid').replaceAll(/<\/?[^>]+(>|$)/g, ''),
                            name: 'rewards',
                            type: 'scan_and_submit',
                            generate_lead: 'False',
                            step: 'step_6',
                        },
                    });
                    this.isSubmittingPromocode = false;
                }
            },
            async deletePromocode () {
                try {
                    const response = await axios.post(`/api/purchase-submissions/delete-promo-code/${this.patient.purchase_submission_id}`);
                    this.promocodeApplied = false;
                    this.promocodeFormData.code = '';
                    this.isSubmittingPromocode = false;
                    this.promocodeBonusAmount = '';
                    this.$emit('promocodeApplied', { promocodeBonusAmount: this.promocodeBonusAmount });
                    return Boolean(response.data?.message === 'promocode deleted');
                }
                catch (error) {
                    console.error('error', error);
                }
                return false;
            },
            getPreviousBonus () {
                // For express rewards, when promocode is applied but form is not saved
                // this method will fetch the applied promocode value
                const keys = Object.keys(this.previousPromocodeApplied);
                this.promocodeBonusAmount = this.previousPromocodeApplied[keys];
            },
        },
    };
