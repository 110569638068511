
import axios from 'axios';
import api from '../../api';

const estimatorEndpoint = 'opt-in/';
const getDefaultState = () => ({
    optInStatus: 0,
    patientName: '',
    patientEmail: '',
    patientId: undefined,
    patientType: undefined,
    ecp: undefined,
    brand: undefined,
    rewards: [],
    nextEligibleDate: undefined,
    nextRewardDate: undefined,
});

const state = getDefaultState();

const getters = {
    getOptInStatus (state) {
        return state.optInStatus;
    },
    getPatientName (state) {
        return state.patientName;
    },
    getPatientEmail (state) {
        return state.patientEmail;
    },
    getPatientId (state) {
        return state.patientId;
    },
    getPatientType (state) {
        return state.patientType;
    },
    getEcpInfo (state) {
        return state.ecp;
    },
    getRewardInfo (state) {
        return state.rewards;
    },
    getBrand (state) {
        return state.brand;
    },
    getNextEligibleDate (state) {
        return state.nextEligibleDate;
    },
    getNextRewardDate (state) {
        return state.nextRewardDate;
    },
};

const mutations = {
    updateInfo (state, data) {
        if (!Array.isArray(data)) {
            data = [data];
        }

        for (const values of data) {
            for (const key in values) {
                state[key] = values[key];
            }
        }
    },
};

const actions = {
    resetState ({ commit }) {
        return commit('updateInfo', getDefaultState());
    },

    async getProfileOptInData ({ dispatch, commit }, data) {
        try {
            const response = await dispatch('makeCall', {
                type: 'get',
                endpoint: `${estimatorEndpoint}`,
                data,
            });
            commit('updateInfo', { optInStatus: response?.data?.email_opt_in });
            return response?.data;
        }

        catch (e) {
            console.log(e);
        }
    },

    async submitProfileOptInData ({ dispatch, commit }, data) {
        try {
            const response = await dispatch('makeCall', {
                type: 'post',
                endpoint: `${estimatorEndpoint}`,
                data,
            });
            return response?.data;
        }

        catch (e) {
            console.log(e);
        }
    },

    async submitRewardData ({ dispatch, commit }, data) {
        try {
            const response = await dispatch('makeCall', {
                type: 'post',
                endpoint: 'eligible-rewards/public',
                data,
            });
            commit('updateInfo', { rewards: response?.data?.eligibleRewards });
            commit('updateInfo', { nextEligibleDate: response?.data?.eligibleDate });
            commit('updateInfo', { nextRewardDate: response?.data?.nextRewardDate });
            commit('updateInfo', { patientType: response?.data?.patientType });
            return response?.data;
        }

        catch (e) {
            console.log(e);
        }
    },

    async sendEstimatorResultsEmail ({ dispatch, commit, rootState }, data) {
        try {
            const response = await dispatch('makeCall', {
                type: 'post',
                endpoint: 'opt-in/send_results',
                data,
            });
            return response?.data?.result;
        }

        catch (e) {
            console.log(e);
        }
    },

    getStoredEcpInfo ({ commit }) {
        const storedEcpDetails = localStorage.getItem('ecpInfo');
        if (storedEcpDetails) {
            const storedData = JSON.parse(storedEcpDetails);
            commit('updateInfo', { ecp: storedData });
        }
    },

    async setEcp ({ commit }, data) {
        const ecpInfoObject = JSON.stringify(data);
        localStorage.setItem('ecpInfo', ecpInfoObject);
        commit('updateInfo', { ecp: data });
    },

    async setBrand ({ commit }, data) {
        commit('updateInfo', { brand: data });
    },

    async setPatientName ({ commit }, data) {
        commit('updateInfo', { patientName: data });
    },

    async setPatientEmail ({ commit }, data) {
        commit('updateInfo', { patientEmail: data });
    },

    async setPatientId ({ commit }, data) {
        commit('updateInfo', { patientId: data });
    },

    async setOptInStatus ({ commit }, data) {
        commit('updateInfo', { optInStatus: data });
    },

    async makeCall ({ commit }, {
        type = 'post',
        endpoint,
        data = {},
        params = {},
    }) {
        try {
            const response = await axios[type](`${api.base}/${endpoint}`, data, { params });
            return response;
        }
        catch (error) {
            console.error(
                `error making ${endpoint} call`,
                error.message,
                error,
            );

            throw error;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
