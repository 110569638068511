
    import { useI18next } from '@composables/i18next';
    import BaseTooltip from './BaseTooltip';

    export default {
        components: {
            BaseTooltip,
        },

        props: {
            icon: {
                required: true,
                type: String,
            },
            node: {
                required: true,
                type: String,
            },
        },

        setup () {
            const { t } = useI18next(['reward_estimator']);
            return { t };
        },
    };
