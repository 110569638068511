import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal",
  role: "dialog"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", {
    ref: "el",
    class: "modal-overlay",
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString($setup.t('annual_supply_limit_admin_confirm.headline')), 1),
      _withDirectives(_createElementVNode("div", null, null, 512), [
        [_directive_t, 'product_intake:annual_supply_limit_admin_confirm.body']
      ]),
      _withDirectives(_createElementVNode("button", {
        class: "button with-right-arrow",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal('confirm')), ["prevent"]))
      }, null, 512), [
        [_directive_t, 'product_intake:annual_supply_limit_admin_confirm.cta_1']
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
      _withDirectives(_createElementVNode("button", {
        class: "button tertiary with-underline",
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal('cancel')), ["prevent"]))
      }, null, 512), [
        [_directive_t, 'product_intake:annual_supply_limit_admin_confirm.cta_2']
      ])
    ])
  ], 512))
}