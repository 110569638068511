
// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments

const
    PurchaseInfoPage = async () => import(
        /* webpackChunkName: 'purchase-info' */
        '../views/PurchaseInfoPage.vue'
    ),
    ProductIntakePage = async () => import(
        /* webpackChunkName: 'purchase-info' */
        '../views/ProductIntakePage.vue'
    ),
    ProductScanPage = async () => import(
        /* webpackChunkName: 'purchase-info' */
        '../views/ProductScanPage.vue'
    ),
    ReceiptUploadPage = async () => import(
        /* webpackChunkName: 'purchase-info' */
        '../views/ReceiptUploadPage.vue'
    ),
    RewardEstimatorPage = async () => import(
        /* webpackChunkName: 'purchase-info' */
        '../views/RewardEstimatorPage.vue'
    ),
    RewardEstimatorSMSOptInPage = async () => import(
        /* webpackChunkName: 'purchase-info' */
        '../views/RewardEstimatorSMSOptInPage.vue'
    ),
    RewardEstimatorEmailOptInPage = async () => import(
        /* webpackChunkName: 'purchase-info' */
        '../views/RewardEstimatorEmailOptInPage.vue'
    ),
    RewardEstimatorEcpSearchPage = async () => import(
        /* webpackChunkName: 'purchase-info' */
        '../views/RewardEstimatorEcpSearchPage.vue'
    ),
    RewardEstimatorIntakePage = async () => import(
        /* webpackChunkName: 'purchase-info' */
        '../views/RewardEstimatorIntakePage.vue'
    ),
    RewardEstimatorResultsPage = async () => import(
        /* webpackChunkName: 'purchase-info' */
        '../views/RewardEstimatorResultsPage.vue'
    );

export default [
    { path: '/purchase_info', component: PurchaseInfoPage, meta: { prevPath: ['/patient_entry'] } },
    { path: '/purchase_info/:hasDate', component: PurchaseInfoPage, props: true, meta: { prevPath: ['/patient_entry'] } },
    { path: '/product_intake', component: ProductIntakePage, meta: { prevPath: ['/purchase_info'] } },
    { path: '/product_scan', component: ProductScanPage, meta: { prevPath: ['/purchase_info'] } },
    { path: '/receipt_upload', component: ReceiptUploadPage, meta: { prevPath: ['product_intake'] } },
    { path: '/reward_estimator', component: RewardEstimatorPage, meta: { public: true } },
    { path: '/sms_opt_in', component: RewardEstimatorSMSOptInPage, meta: { public: true } },
    { path: '/email_opt_in', component: RewardEstimatorEmailOptInPage, meta: { public: true } },
    { path: '/estimator_ecp_search', component: RewardEstimatorEcpSearchPage, meta: { public: true } },
    { path: '/estimator_intake', component: RewardEstimatorIntakePage, meta: { public: true } },
    { path: '/estimator_results', component: RewardEstimatorResultsPage, meta: {
        prevPath: ['/estimator_intake'],
        public: true,
    } },
];
