import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lds-default" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", null, null, -1),
    _createElementVNode("div", null, null, -1),
    _createElementVNode("div", null, null, -1),
    _createElementVNode("div", null, null, -1),
    _createElementVNode("div", null, null, -1),
    _createElementVNode("div", null, null, -1),
    _createElementVNode("div", null, null, -1),
    _createElementVNode("div", null, null, -1),
    _createElementVNode("div", null, null, -1),
    _createElementVNode("div", null, null, -1),
    _createElementVNode("div", null, null, -1),
    _createElementVNode("div", null, null, -1)
  ])))
}