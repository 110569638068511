
    import axios from 'axios';
    import lodash from 'lodash';
    import { mapState, mapGetters } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import { MailIcon, StarIcon } from '@components/images/';
    import PreferredTooltip from '@components/tooltips/PreferredTooltip.vue';
    import BaseModal from './BaseModal';
    export default {
        name: 'PurchaseCompleteModal',

        components: {
            MailIcon,
            StarIcon,
            PreferredTooltip,
        },

        extends: BaseModal,

        props: {
            status: {
                type: String,
                required: true,
            },
            baseReward: {
                type: Number,
                required: true,
            },
            bonusRewards: {
                type: Object,
                required: true,
            },
            promocodeRewards: {
                type: Object,
                required: true,
            },
            confirmationCode: {
                type: String,
                required: true,
            },
            purchase: {
                type: Object,
                required: true,
            },
            purchaseSubmissionId: {
                type: String,
                required: true,
            },
        },

        setup () {
            const { t } = useI18next(['modal_content', 'global', 'product_intake']);
            return { t };
        },

        data () {
            return {
                modalName: 'purchase-complete',
                preferredFlag: false,
            };
        },

        computed: {
            ...mapState(['app']),
            ...mapGetters({
                hasPreferredEcp: 'purchase/hasPreferredEcp',
            }),
            mailRequired () {
                return this.status === 'mail required' || this.status === 'resub_mail_required';
            },
            totalAmount () {
                return parseInt(this.baseReward) + parseInt(this.bonusAmount) + parseInt(this.promocodeAmount);
            },
            bonusAmount () {
                let bonusAmount = 0;

                if (this.bonusRewards && typeof this.bonusRewards === 'object') {
                    Object.values(this.bonusRewards).forEach((value) => {
                        bonusAmount += parseInt(value);
                    });
                }

                return bonusAmount;
            },
            promocodeAmount () {
                let promocodeAmount = 0;

                if (this.promocodeRewards && typeof this.promocodeRewards === 'object') {
                    Object.values(this.promocodeRewards).forEach((value) => {
                        promocodeAmount += parseInt(value);
                    });
                }
                else if (this.promocodeRewards && typeof this.promocodeRewards !== 'object') {
                    promocodeAmount = this.promocodeRewards;
                }

                return promocodeAmount;
            },

            prepaidCard () {
                let copy = this.t('global:prepaid_card');
                copy = (typeof copy === 'string') ? { default: {} } : copy;
                const { card_format = {} } = (copy[this.app.subPhase]) || (copy.default);
                const { first_card_mention = '', second_card_mention = '', following_card_mentions = '' } = card_format;
                return { first_card_mention, second_card_mention, following_card_mentions };
            },

            supplyLength () {
                const supplyLengthNumber = this.purchase.supplies.supplyLength;
                return this.t(`purchase_complete.mail_confirm.supply_amounts.${supplyLengthNumber}`);
            },
            productDescriptionLeft () {
                const brand = this.purchase.supplies.brand_l;
                return this.t(`product_intake:product_supply.${brand}`);
            },
            productDescriptionRight () {
                const brand = this.purchase.supplies.brand_r;
                return this.t(`product_intake:product_supply.${brand}`);
            },
            productDescription () {
                const brand =
                    this.purchase.supplies?.brand_l ||
                    this.purchase.supplies?.brand_r;
                return this.t(`product_intake:product_supply.${brand}`);
            },
            termsLink () {
                const termsLink = `${this.app.siteURL}?locale=${this.app.locale}/#/preferredRewardsTerms`;
                return termsLink;
            },
        },

        mounted () {
            this.findPatientData();
        },

        methods: {
            async findPatientData () {
                try {
                    const getData = await axios.get('/api/purchase-submissions/all/me');
                    const arrayNum = lodash.filter(getData.data.result, { 'id': this.purchaseSubmissionId });
                    if (arrayNum.length > 0) {
                        this.preferredFlag = arrayNum[0].eye_care_professional.preferred;
                    }
                }
                catch (error) {
                    console.error('error', error);
                }
            },
        },
    };
