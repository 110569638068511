
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        name: 'EligibilityModal',

        extends: BaseModal,

        props: {
            // Note: if you pull these values from copydeck, you need to make
            // sure the namespace is loaded before we need this component, ideally at
            // app startup, since the user may not have a viable Internet connection
            // when this modal appears.
            headline: {
                type: String,
                default: '',
            },
            body: {
                type: String,
                default: '',
            },
        },

        setup () {
            const { t } = useI18next(['buttons']);
            return { t };
        },

        data () {
            return {
                modalName: 'eligibility',
            };
        },
    };
