import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "ecp-search-results-headline" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PlacemarkerIcon = _resolveComponent("PlacemarkerIcon")
  const _component_LoadingIcon = _resolveComponent("LoadingIcon")
  const _component_ECPSearchAndSelect = _resolveComponent("ECPSearchAndSelect")

  return (_openBlock(), _createElementBlock("div", {
    ref: "el",
    class: "modal-overlay",
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => ($options.closeModalAndShowManualEcpSearch()), ["self"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal location-service-modal", { 'modal-padding': !$data.searchCompleted }]),
      role: "dialog"
    }, [
      (!_ctx.selectionWasMade && $data.locationServicesAllowed == undefined && !$data.searchCompleted)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_PlacemarkerIcon),
            _createElementVNode("h2", null, _toDisplayString($setup.t('headline')), 1),
            _createElementVNode("div", {
              innerHTML: $setup.t('body')
            }, null, 8, _hoisted_1),
            _createElementVNode("p", null, [
              _createElementVNode("button", {
                class: "with-right-arrow",
                onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($options.areLocationServicesAllowed(true)), ["prevent"]))
              }, _toDisplayString($setup.t('allow')), 1)
            ]),
            _createElementVNode("p", null, [
              _createElementVNode("a", {
                class: "link-do-not-allow",
                href: "#",
                onClick: _cache[1] || (_cache[1] = _withModifiers($event => ($options.areLocationServicesAllowed(false)), ["prevent"]))
              }, _toDisplayString($setup.t('do_not_allow')), 1)
            ])
          ], 64))
        : (_ctx.selectionWasMade && $data.locationServicesAllowed == true && !$data.searchCompleted)
          ? (_openBlock(), _createBlock(_component_LoadingIcon, { key: 1 }))
          : _createCommentVNode("", true),
      ($data.searchCompleted)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createElementVNode("h2", _hoisted_2, _toDisplayString($setup.t('ecp_results.headline')), 1),
            _createVNode(_component_ECPSearchAndSelect, { onCloseModal: $options.closeModalAndGoToPatientProductIntake }, null, 8, ["onCloseModal"])
          ], 64))
        : _createCommentVNode("", true)
    ], 2)
  ], 512))
}