
    import { createPopper } from '@popperjs/core';
    export default {
        data () {
            return {
                show: false,
                popperInstance: undefined,
                id: undefined,
                aboveTop: 0,
                isTouchDevice: false,
            };
        },

        computed: {
            cssStyle () {
                return { '--bottom-offset': `${this.aboveTop}px` };
            },
        },

        mounted () {
            this.isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

            this.$nextTick(() => {
                this.popperInstance = createPopper(this.$refs.tooltip, this.$refs.tooltipContainer, {
                    placement: 'top',
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 8],
                            },
                        },
                        {
                            name: 'flip',
                            options: {
                                padding: 8,
                            },
                        },
                        {
                            name: 'preventOverflow',
                        },
                    ],
                });
            });
            window.addEventListener('scroll', this.manageTopPosition);

            if (!this.isTouchDevice) {
                this.$refs.tooltip.addEventListener('mouseover', this.toggleTooltip);
                this.$refs.tooltip.addEventListener('mouseout', this.toggleTooltip);
            }
            else {
                this.$refs.tooltip.addEventListener('click', this.toggleTooltip);
                document.addEventListener('click', this.hideTooltipOnOutsideClick);
            }
        },

        beforeUnmount () {
            window.removeEventListener('scroll', this.manageTopPosition);
            if (!this.isTouchDevice) {
                this.$refs.tooltip.removeEventListener('mouseover', this.toggleTooltip);
                this.$refs.tooltip.removeEventListener('mouseout', this.toggleTooltip);
            }
            else {
                this.$refs.tooltip.removeEventListener('click', this.toggleTooltip);
                document.removeEventListener('click', this.hideTooltipOnOutsideClick);
            }
        },

        methods: {
            hideTooltip () {
                this.timeout = setTimeout(() => {
                    this.show = false;
                }, 500);
            },
            manageTopPosition () {
                this.aboveTop = this.$refs.tooltipContainer.getBoundingClientRect().top;
            },
            toggleTooltip () {
                this.show = !this.show;
                if (this.show) {
                    this.$nextTick(() => this.popperInstance.update());

                    setTimeout(() => {
                        document.addEventListener('click', this.hideTooltipOnOutsideClick);
                    }, 0);
                }
                else {
                    document.removeEventListener('click', this.hideTooltipOnOutsideClick);
                }
            },

            hideTooltipOnOutsideClick (event) {
                if (!this.$refs.tooltip.contains(event.target) && !this.$refs.tooltipContainer.contains(event.target)) {
                    this.show = false;
                }
            },
        },
    };
