import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseTooltip = _resolveComponent("BaseTooltip")

  return (_openBlock(), _createBlock(_component_BaseTooltip, { class: "tooltip-estimator" }, {
    tooltip: _withCtx(() => [
      _createElementVNode("i", {
        class: _normalizeClass(`${$props.icon}`)
      }, null, 2)
    ]),
    content: _withCtx(() => [
      _createElementVNode("p", {
        innerHTML: $setup.t(`intake_form.estimator_tooltip.${$props.node}.body_copy`)
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }))
}